import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from './index';

declare let $cookies;

@Module({ dynamic: true, store, name: 'app', namespaced: true })
class App extends VuexModule {
  bundleId = process.env.VUE_APP_BUNDLE_ID || '';
  token = '';

  @Mutation
  setBundleId(bundleId: string) {
    this.bundleId = bundleId;
  }

  @Mutation
  _setToken(token: string) {
    this.token = token;
  }

  @Action
  setToken(token: string) {
    AppModule._setToken(token);
    $cookies.set('token', token);
  }

  @Action
  clearToken() {
    $cookies.remove('token');
    AppModule._setToken('');
  }
}

export const AppModule = getModule(App);
