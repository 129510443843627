import axios, { AxiosResponse } from 'axios';
import { AppModule } from '../store/app';
import { Logger } from '../utils/logger';

export type RequestMethod = ('get' | 'post' | 'put' | 'delete');

export interface RequestHeader {
  uuid?: string;
  bundleId?: string;
  token?: string;
  Authorization?: string;
}

class ApiServiceSingleton {
  private static _instance: any;

  static createInstance () {
    ApiServiceSingleton.getInstance();
  }

  static getInstance (): ApiServiceSingleton {
    return this._instance || (this._instance = new this());
  }

  request(method: RequestMethod = 'get', url: string, data?: any, headers?: RequestHeader): Promise<any> {
    const defaultHeaders = {
      uuid: 'mobio-register',
      bundleId: AppModule.bundleId,
      Authorization: AppModule.token
    };
    headers = { ...defaultHeaders, ...headers };
    return new Promise((resolve, reject) => {
      let req;

      switch (method) {
        case 'get':
        case 'delete':
          req = axios[method](url, {
            headers
          });
          break;
        case 'post':
        case 'put':
          req = axios[method](url, data, {
            headers
          });
          break;
      }

      if (!req) {
        const err = new Error('method not allowed');
        Logger.error(`[mobio] api-request-error [${url}] error`, err);
        reject(err);
        return;
      }

      req.then((data: AxiosResponse) => {
        resolve(data.data);
      }).catch((error) => {
        Logger.error(`[mobio] api-request-error [${url}] data`, data);
        Logger.error(`[mobio] api-request-error [${url}] error`, error);
        Logger.error(`[mobio] api-request-error [${url}] headers`, headers);
        reject(error);
      })
    });
  }

  get(url: string, headers?: RequestHeader) {
    return this.request('get', url, undefined, headers);
  }

  mockGet(url: string, headers?: RequestHeader) {
    return new Promise((resolve) => {
      setTimeout(() => {
        Logger.debug('[mobio] apiService.mockGet', url, headers)
        resolve(null);
      }, 1000);
    });
  }

  post(url: string, data?: any, headers?: RequestHeader) {
    return this.request('post', url, data, headers);
  }

  mockPost(url: string, data?: any, headers?: RequestHeader) {
    return new Promise((resolve) => {
      setTimeout(() => {
        Logger.debug('[mobio] apiService.mockPost', url, data, headers)
        resolve(null);
      }, 1000);
    });
  }

  put(url: string, data?: any, headers?: RequestHeader) {
    return this.request('put', url, data, headers);
  }

  mockPut(url: string, data?: any, headers?: RequestHeader) {
    return new Promise((resolve) => {
      setTimeout(() => {
        Logger.debug('[mobio] apiService.mockPut', url, data, headers)
        resolve(null);
      }, 1000);
    });
  }

  delete(url: string, headers?: RequestHeader) {
    return this.request('delete', url, undefined, headers);
  }

  mockDelete(url: string, headers?: RequestHeader) {
    return new Promise((resolve) => {
      setTimeout(() => {
        Logger.debug('[mobio] apiService.mockDelete', url, headers)
        resolve(null);
      }, 1000);
    });
  }
}

export const ApiService = ApiServiceSingleton.getInstance()
