
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ApiService, RequestHeader, RequestMethod } from '../services/apiService';

export type InputTypeText = ('text'|'password'|'email'|'number'|'hidden'|'checkbox');

export class FormField {
  name: string;
  type: ('email' | 'text' | 'password' | 'number' | 'hidden' | 'checkbox');
  eye?: boolean;
  label: string;
  tabindex?: number;
  validations: ((value: any) => string | boolean)[] = [() => true];
  errors: string[] = [];
  value?: any;
  api?: boolean = true;

  constructor(props: {
    name: FormField['name'];
    type: FormField['type'];
    eye?: FormField['eye'];
    label?: FormField['label'];
    tabindex?: FormField['tabindex'];
    validations?: FormField['validations'];
    value?: FormField['value'];
    api?: FormField['api'];
  }) {
    this.name = props.name;
    this.type = props.type;
    this.eye = props.eye;
    this.label = props.label;
    this.tabindex = props.tabindex;
    if(props.validations !== undefined) {
      this.validations = props.validations;
    }
    this.value = props.value;
    if(props.api !== undefined) {
      this.api = props.api;
    }
  }
}

@Component
export default class MobioForm extends Vue {
  @Prop({ required: true }) method: RequestMethod;
  @Prop() url: RequestMethod;
  @Prop() header: RequestHeader;
  @Prop({ required: true }) fields: {[key: string]: FormField};
  @Prop({ required: true }) submitLabel: string;
  @Prop() beforeSubmit: () => Promise<any>;
  @Prop({ default: true }) redirectOnError: boolean;
  errors: string[] = [];
  processing = false;
  test = '';
  revealPassword = false;

  onSubmit(event) {
    this.errors = [];
    this.processing = true;
    this.internalBeforeSubmit().then(() => {
      let error = false;
      const data: {[key: string]: any} = {};

      for(const name in this.fields) {
        const field = this.fields[name];
        if(field.api) {
          data[field.name] = field.value;
        }
        field.errors = [];
        field.validations.forEach(validation => {
          const validText = validation(field.value);
          if(validText && validText !== true) {
            field.errors.push(validText);
          }
        });
        if(field.errors.length) {
          error = true;
        }
      }

      if(!error) {
        const method = this.method as string;
        // if(process.env.NODE_ENV === 'development') {
        //   method = 'mock' + this.method.charAt(0).toUpperCase() + this.method.slice(1);
        // }

        if(this.url) {
          let req: Promise<any>;
          switch(method) {
            case 'get':
            case 'mockGet':
            case 'delete':
            case 'mockDelete':
              req = ApiService[method](this.url, this.header);
              break;
            case 'post':
            case 'mockPost':
            case 'put':
            case 'mockPut':
              req = ApiService[method](this.url, data, this.header);
              break;
          }

          req.then((response) => {
            this.$emit('submitted', response, event);
          }).catch((error) => {
            if(error.response?.data?.code === 'ER_DUP_ENTRY') {
              this.errors.push(`errorDuplicate${error.response?.data?.field}`);
              this.$emit('error', {
                error,
                event
              });
            } else if(!this.redirectOnError) {
              this.$emit('error', {
                error,
                event
              });
            } else {
              this.$router.push({ name: 'error' });
            }
          }).finally(() => {
            this.processing = false;
          });
        } else {
          this.$emit('submitted', event);
        }
      } else {
        this.processing = false;
      }
    }).catch(error => {
      this.processing = false;
      this.errors.push('errorUnknown')
      console.error('[mobio] MobioForm.onSubmit', error);
    });
  }

  internalBeforeSubmit(): Promise<void> {
    if (this.beforeSubmit) {
      return this.beforeSubmit();
    }

    return new Promise((resolve) => resolve());
  }

  /**
   * toogle reveal/hide password state
   */
  toggleRevealPassword() {
    this.revealPassword = !this.revealPassword;
  }

  /**
   * modify input type if applicable
   * @param {InputTypeText} type of input
   * @returns {InputTypeText}
   */
  inputType(type: InputTypeText): InputTypeText {
    return ((type === 'password') && this.revealPassword) ? 'text' : type;
  }

  get revealPasswordChecked(): boolean {
    return this.revealPassword;
  }

  get hasPasswordInput(): boolean {
    let result = false;
    Object.keys(this.fields).forEach(field => {
      if (this.fields[field].type === 'password') {
        result = true;
      }
    });
    return result;
  }
}
