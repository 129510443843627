
import { Component, Vue } from 'vue-property-decorator';
import MobioPageDescription from '../../../components/MobioPageDescription.vue';
import MobioForm, { FormField } from '../../../components/MobioForm.vue';
import { FormService } from '../../../services/formService';
import MobioLogo from '../../../components/MobioLogo.vue';

@Component({
  components: {
    MobioForm,
    MobioPageDescription,
    MobioLogo
  }
})
export default class Register extends Vue {
  fields = {
    origin: new FormField({
      name: 'origin',
      type: 'hidden',
      value: 'haufe:register'
    }),
    email: new FormField({
      name: 'email',
      type: 'email',
      validations: [FormService.validateEmail],
      label: 'labelEmailInput',
      tabindex: 1
    }),
    password: new FormField({
      name: 'password',
      type: 'password',
      validations: [FormService.validatePassword],
      label: 'labelNewPasswordInput',
      tabindex: 2,
      eye: true
    }),
    passwordRepeat: new FormField({
      name: 'passwordRepeat',
      type: 'password',
      validations: [
        () => {
          return (this.fields.password.value !== this.fields.passwordRepeat.value ? 'errorDifferentPasswords' : true)
        },
        FormService.validateRequired
      ],
      label: 'labelPasswordInputRepeat',
      tabindex: 3
    }),
    privacyAccept: new FormField({
      name: 'privacyAccept',
      type: 'checkbox',
      label: 'labelPrivacyCheck',
      validations: [
        () => {
          if (FormService.validateRequired(this.fields.privacyAccept.value) === 'errorEmpty') {
            return 'errorPrivacy';
          }
          return true;
        }
      ],
      tabindex: 4
    }),
    saveDataAccept: new FormField({
      name: 'saveDataAccept',
      type: 'checkbox',
      label: 'labelSaveDataCheck',
      validations: [
        () => {
          if (FormService.validateRequired(this.fields.saveDataAccept.value) === 'errorEmpty') {
            return 'errorSaveData';
          }
          return true;
        }
      ],
      tabindex: 5
    })
  };

  submitted() {
    this.$router.push({ path: '/register/sent', query: { email: this.fields.email.value } });
  }

  get url() {
    return `${process.env.VUE_APP_API_URL}/auth/register`;
  }
}
