import { Route } from 'vue-router';
import { AppModule } from '../store/app';
import { Guard } from './Guard';

declare let $cookies;

export class TokenGuard extends Guard {
  handleRedirect = true;
  canActivate(to: Route, from: Route, next) {
    const tokenValid = !!(AppModule.token || $cookies.get('token') || to.query.token);

    if(!tokenValid) {
      next(vm => {
        vm.$router.push('/');
      });
    }

    return tokenValid;
  }
}
