
import { Component, Vue } from 'vue-property-decorator';
import { ApiService } from '../../../services/apiService';
import { AppModule } from '../../../store/app';
import MobioLogo from '../../../components/MobioLogo.vue';

@Component({
  components: { MobioLogo }
})
export default class RegisterVerify extends Vue {
  mounted() {
    const token = this.$route.params.token;
    ApiService.get(process.env.VUE_APP_API_URL + '/auth/verify', {
      Authorization: token
    })
      .then((response) => {
        AppModule.setToken(response.token);
        this.$router.push('/login');
      }).catch(() => {
        this.$router.push('/error');
      });
  }
}
