
import { Component, Vue } from 'vue-property-decorator'
import MobioPageDescription from '../components/MobioPageDescription.vue';

@Component({
  components: {
    MobioPageDescription
  }
})
export default class MobioError extends Vue {
  get error() {
    const error = this.$route.params.error || 'unknown';
    return 'error' + error.charAt(0).toUpperCase() + error.slice(1);
  }

  onBack() {
    this.$router.go(-1);
  }
}
