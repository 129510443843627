
import { Component, Prop, Vue } from 'vue-property-decorator';
import MobioPageDescription from './MobioPageDescription.vue';
import { ApiService } from '../services/apiService';

@Component({
  components: { MobioPageDescription }
})
export default class MobioApps extends Vue {
  @Prop({ default: [] }) bundleIds;

  checkedApp = -1;

  get appLinks() {
    const appIds = [
      {
        bundleId: 'de.haufe.cm',
        android: 'https://play.google.com/store/apps/details?id=de.haufe.cm',
        ios: 'https://apps.apple.com/de/app/controller-magazin/id1581779546',
        webviewer: 'https://magazine.haufe.de/controllermagazin/login/',
        title: 'Controller Magazin'
      },
      {
        bundleId: 'de.haufe.immobilienwirtschaft',
        android: 'https://play.google.com/store/apps/details?id=de.haufe.immobilienwirtschaft',
        ios: 'https://apps.apple.com/de/app/immobilienwirtschaft-magazin/id1481076781',
        webviewer: 'https://magazine.haufe.de/immobilienwirtschaft/login/',
        title: 'Immobilienwirtschaft Magazin'
      },
      {
        bundleId: 'de.haufe.pm',
        android: 'https://play.google.com/store/apps/details?id=de.haufe.pm',
        ios: 'https://apps.apple.com/de/app/personalmagazin/id916189295',
        webviewer: 'https://magazine.haufe.de/personalmagazin/login/',
        title: 'Personalmagazin'
      },
      {
        bundleId: 'de.haufe.nele',
        android: 'https://play.google.com/store/apps/details?id=de.haufe.nele',
        ios: 'https://apps.apple.com/de/app/personalmagazin-neues-lernen/id1672533198',
        webviewer: 'https://magazine.haufe.de/neues-lernen/login/',
        title: 'Personalmagazin Neues Lernen'
      },
      {
        bundleId: 'de.haufe.wohnungswirtschaft',
        android: 'https://play.google.com/store/apps/details?id=de.haufe.wohnungswirtschaft',
        ios: 'https://apps.apple.com/de/app/dw-die-wohnungswirtschaft/id1481076014',
        webviewer: 'https://magazine.haufe.de/diewohnungswirtschaft/login/',
        title: 'Die Wohnungswirtschaft'
      }
    ].filter((app) => this.bundleIds.indexOf(app.bundleId) > -1);

    if(appIds.length > 0) {
      this.checkedApp = 0;
    }

    return appIds;
  }

  get os() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'android';
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'ios';
    }

    return 'browser';
  }

  get token() {
    return this.$cookies.get('token');
  }

  async openWebviewer(app) {
    try {
      const response = await ApiService.get(`${process.env.VUE_APP_API_URL}/auth/renew-token`, {
        bundleId: app.bundleId
      });
      location.href = app.webviewer + '?token=' + response.authToken;
    } catch(e) {
      await this.$router.push({ name: 'error' });
    }
  }
}
