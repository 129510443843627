import { Guard } from './Guard';

export function Guards<T extends Guard>(...guards: (new () => T)[]) {
  return function(target) {
    const oldBeforeRouterEnter = target.prototype.beforeRouteEnter;
    target.prototype.beforeRouteEnter = (to, from, next) => {
      if(oldBeforeRouterEnter) {
        oldBeforeRouterEnter(to, from, next);
      }

      let canActivate = true;
      let handleRedirect = true;
      guards.forEach((Guard) => {
        const guardInstance = new Guard();
        const guardCanActivate = guardInstance.canActivate(to, from, next);
        if(!guardCanActivate) {
          canActivate = false;
        }
        if(guardInstance.handleRedirect) {
          handleRedirect = true;
        }
      });

      if(canActivate) {
        next();
      } else if(handleRedirect) {
        next(vm => {
          vm.$router.push('/error');
        });
      }
    };

    return target;
  }
}
