
import { Component, Vue } from 'vue-property-decorator';
import MobioPageDescription from '../../../components/MobioPageDescription.vue';
import { Guards } from '../../../guards/GuardDecorator';
import { TokenGuard } from '../../../guards/TokenGuard';
import MobioForm, { FormField } from '../../../components/MobioForm.vue';
import MobioApps from '../../../components/MobioApps.vue';
import { FormService } from '../../../services/formService';
import { AppModule } from '../../../store/app';
import MobioLogo from '../../../components/MobioLogo.vue';

@Component({
  components: { MobioApps, MobioForm, MobioPageDescription, MobioLogo }
})
@Guards(TokenGuard)
export default class Coupons extends Vue {
  fields = {
    code: new FormField({
      name: 'code',
      type: 'text',
      label: 'labelCouponCode',
      validations: [FormService.validateRequired]
    })
  };

  codeStatus: ('new' | 'success' | 'failed') = 'new';
  gettedApps: string[] = [];

  submitted(response) {
    this.codeStatus = response.status;
    this.gettedApps = response.apps;
  }

  returnToCode() {
    this.codeStatus = 'new';
    this.gettedApps = [];
  }

  signOut() {
    AppModule.clearToken();
    this.$router.push('/login');
  }

  get url() {
    return `${process.env.VUE_APP_API_URL}/coupons/check`;
  }
}
