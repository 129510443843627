
import { Component, Vue } from 'vue-property-decorator';
import MobioPageDescription from '../../../components/MobioPageDescription.vue';
import MobioForm, { FormField } from '../../../components/MobioForm.vue';
import { FormService } from '../../../services/formService';
import { AppModule } from '../../../store/app';
import MobioLogo from '../../../components/MobioLogo.vue';

@Component({
  components: { MobioForm, MobioPageDescription, MobioLogo }
})
export default class Login extends Vue {
  fields = {
    email: new FormField({
      name: 'email',
      type: 'email',
      validations: [FormService.validateRequired],
      label: 'labelEmailInput',
      tabindex: 1
    }),
    password: new FormField({
      name: 'password',
      type: 'password',
      validations: [FormService.validateRequired],
      label: 'labelPasswordInput',
      tabindex: 2,
      eye: true
    })
  };

  mounted() {
    const token = this.$cookies.get('token');
    if(token) {
      AppModule.setToken(token);
      this.redirect();
    }
  }

  submitted(response) {
    AppModule.setToken(response.token);
    this.redirect();
  }

  redirect() {
    this.$router.push('/coupons');
  }

  get url() {
    return `${process.env.VUE_APP_API_URL}/auth/signin`;
  }
}
