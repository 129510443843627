
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class MobioLanguageChange extends Vue {
  dropdownOpen = false;

  changeLang (lang: string) {
    this.$i18n.locale = lang;
  }
}

