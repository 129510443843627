import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import MobioError from '../views/MobioError.vue';
import Register from '../views/guest/register/Register.vue';
import RegisterSent from '../views/guest/register/RegisterSent.vue';
import RegisterVerify from '../views/guest/register/RegisterVerify.vue';
import Login from '../views/guest/login/Login.vue';
import Coupons from '../views/user/coupons/Coupons.vue';
import Privacy from '../views/guest/privacy/Privacy.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { name: 'root', path: '/', component: Register },
  { name: 'register', path: '/register', component: Register },
  { name: 'registerSent', path: '/register/sent', component: RegisterSent },
  { name: 'registerVerify', path: '/register/verify/:token', component: RegisterVerify },

  { name: 'privacy', path: '/privacy', component: Privacy },

  { name: 'login', path: '/login', component: Login },

  { name: 'coupons', path: '/coupons', component: Coupons },

  { name: 'error', path: '/error/:error?', component: MobioError }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
