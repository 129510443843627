
import { Component, Vue } from 'vue-property-decorator'
import MobioPageDescription from '../../../components/MobioPageDescription.vue';
import { namespace } from 'vuex-class';
import MobioLogo from '../../../components/MobioLogo.vue';

const app = namespace('app');

@Component({
  components: {
    MobioPageDescription,
    MobioLogo
  }
})
export default class RegisterSent extends Vue {
  @app.State('isApp') isApp: boolean;

  description() {
    return this.clean(this.$t('description1') as string).replace('[profile.newEmail]', (this.$route.query.email as string));
  }

  onBack() {
    this.$router.push('/');
  }

  clean(text: string): string {
    if (
      !text ||
      (typeof text !== 'string')
    ) {
      return '';
    }

    let result = '';

    const cleanText = text;

    // eliminate HTML tags
    if (cleanText) {
      const nodeDiv = document.createElement('div');
      nodeDiv.innerHTML = cleanText;
      result = nodeDiv.textContent || nodeDiv.innerText;
    }

    return result;
  }
}
