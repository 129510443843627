class FormServiceSingleton {
  private static _instance: any;

  static createInstance () {
    FormServiceSingleton.getInstance();
  }

  static getInstance (): FormServiceSingleton {
    return this._instance || (this._instance = new this());
  }

  validateEmail(email: string): (string | boolean) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!(email && regex.test(email))) {
      return 'errorInvalidEmail';
    }
    return true;
  }

  validatePassword(password: string): (string | boolean) {
    const regexRequirements = /(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/;
    const regexBlacklist = /[\\]/;
    if(!regexRequirements.test(password) || regexBlacklist.test(password)) {
      return 'errorPasswordRequirements';
    }
    return true;
  }

  validateNumber(number: number): (string | boolean) {
    if(isNaN(number)) {
      return 'errorNotANumber';
    }
    return true;
  }

  validateRequired(value: any) {
    if(
      (value === undefined) ||
      (value === false) ||
      (typeof value === 'string' && value.length === 0) ||
      (typeof value === 'number' && value === undefined)
    ) {
      return 'errorEmpty';
    }

    return true;
  }
}

export const FormService = FormServiceSingleton.getInstance()
