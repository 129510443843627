import './utils/class-component-hooks'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.mixin({

});

Vue.use(VueCookies);
Vue.$cookies.config('7d')

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
