
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class MobioPageDescription extends Vue {
  @Prop({ required: true }) headline: string;
  @Prop() icon: string;
  @Prop({ default: 'success' }) iconClass: string;
  @Prop() description1: string;
  @Prop() description2: string;
}

