export class Logger {
  static info(...args: any[]) {
    Logger.call('info', ...args);
  }

  static debug(...args: any[]) {
    Logger.call('debug', ...args);
  }

  static error(...args: any[]) {
    Logger.call('error', ...args);
  }

  static log(...args: any[]) {
    Logger.call('log', ...args);
  }

  private static call(method: string, ...args: any[]) {
    if(process.env.VUE_APP_DEBUG === 'true') {
      if (typeof console[method] === 'function') {
        console[method](...args);
      } else {
        console.error(`${method} is not a function or undefined`);
      }
    }
  }
}
