
import { Component, Vue } from 'vue-property-decorator'
import MobioLanguageChange from './components/MobioLanguageChange.vue';
import { AppModule } from './store/app';
import MobioLogo from './components/MobioLogo.vue';

@Component({
  components: {
    MobioLanguageChange,
    MobioLogo
  }
})
export default class App extends Vue {
  mounted() {
    this.$i18n.locale = (this.$route.query.lang as string) || process.env.VUE_APP_I18N_LOCALE || 'en';

    AppModule.setBundleId(process.env.VUE_APP_BUNDLE_ID);
    const token = this.$cookies.get('token') || this.$route.query.token;
    if (token) {
      AppModule.setToken(token);
    }
  }

  get imprintUrl() {
    return process.env.VUE_APP_IMPRINT_URL;
  }
}
